<template>
  <div class="p-reset-password">
    <header class="p-reset-password-header">
      <section class="p-reset-password-header-box">
        <div class="p-reset-password-header_left">
          <figure class="p-reset-password-header_logo">
            <a href="https://www.bom.ai">
              <img alt="" src="//oss.bom.ai/erp-static/img/erp-logo-v2.png">
            </a>
          </figure>
          <span class="p-reset-password-header_line"></span>
          <span class="p-reset-password-header_left_text">修改密码</span>
        </div>
        <p class="p-reset-password-header-text" @click="onLogin">
          <a href="javascript:void(0)">去登录>></a>
        </p>
      </section>

    </header>
    <article class="p-reset-password-box">
      <section class="p-reset-password-box_content">
        <h3 class="header">重置密码</h3>
        <div class="info">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-position="left"
                    status-icon>
            <el-form-item  prop="company" required>
              <el-input v-model="ruleForm.company" autocomplete="off" placeholder="公司名称"
                        type="text"></el-input>
            </el-form-item>
            <el-form-item required>
              <el-row :gutter="2">
                <el-col :span="12">
                  <el-form-item prop="phone">
                    <el-input v-model.number="ruleForm.phone" placeholder="手机号码" type="text"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input v-model.number="ruleForm.code" :span="11" placeholder="验证码" type="text"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <znl-button :disabled="codeDisabled" :height="40" :width="85" style-type="minor" @click="onGetCode">
                    {{ codeBtnText }}
                  </znl-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="pass" required>
              <el-input type="password" v-model.number="ruleForm.pass" placeholder="密码" ></el-input>
            </el-form-item>
            <el-form-item prop="configPass" required>
              <el-input type="password" v-model.number="ruleForm.configPass" placeholder="确认密码" ></el-input>
            </el-form-item>
            <el-form-item>
              <znl-button :height="40" style="width: 100%" style-type="main" @click="submitForm('ruleForm')">确认修改
              </znl-button>
            </el-form-item>
          </el-form>
        </div>
      </section>
    </article>

    <div style="background-color: #eee;">
      <footer class="p-reset-password-center p-login-footer">
        <section class="p-login-footer_content">
          <div class="p-login-footer_text">
            <p>
              〖深圳总部〗：深圳市福田区华强北街道振兴路桑达工业区418栋曼哈数码广场A栋四楼双创中心东面
            </p>
            <p>
              〖香港〗：九龍觀塘成業街19-21號，成業工業大廈3樓33室 ADDRESS: Unit 33, 3/F.,Shing Yip Industrial Building,No.19-21 Shing Yip
              Street,Kwun Tong,Kowloon.
            </p>
            <p>〖上海〗：上海市静安区天目西路218嘉里不夜城b座1207室</p>
            <p>
              <span>客服QQ：800106568</span>
              <span> 客服电话：400-699-2899</span>
            </p>
          </div>
          <div class="p-login-footer_img">
            <figure><img alt="" src="//static.bom.ai/assets/img/header_wechat_qrcode.png"><span
              style="width: 80px;">官方公众号</span></figure>
            <figure><img alt="" src="//static.bom.ai/assets/img/header_qywechat_qrcode.png "><span>正能量微信客服</span></figure>
          </div>
        </section>
        <section class="p-login-footer_info">
          <a href="https://beian.miit.gov.cn/" style="color: rgb(153, 153, 153);">
            ©{{ copyright }} 深圳市正能量网络技术有限公司|粤ICP备17005480号</a>
        </section>
      </footer>
    </div>

  </div>
</template>

<script>
export default {
  name: 'RegisterView',
  data() {
    let validatePass = (rule, value, callback) => {
      const rules = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,30}$/
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!rules.test(value)) {
        callback(new Error('密码为 3~18位数字、字母任意组合'))
      } else {
        callback()
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };


    let validatePhone = (rule, value, callback) => {
      const rules = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!rules.test(value)) {
        callback(new Error('请输入正确的手机号!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        configPass: '', // 确认密码
        pass: '', // 密码
        company: '', // 公司名称
        phone: '', // 手机号
        code: '', // 验证码
      },
      rules: {
        configPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        phone: [
          {validator: validatePhone, trigger: 'blur'}
        ],
        company: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ]
      },
      codeDisabled: false,
      codeBtnText: '获取验证码'
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const editMaps = {
            "CompanyName": "company",
            "PWD": "pass",
            "Mobile": "phone",
            "Captcha": "code"
          }
          const params = {}

          Object.entries(editMaps).forEach(([key, src]) => {
            params[key] = this.ruleForm[src]
          })

          this.$post('Account/ResetMainAccountPwd', params, (data, log) => {
            if (log.code === 200) {
              this.$message({
                message: '密码修改成功，即将跳转登录页面',
                type: 'success'
              })
              this.$router.push('/login');
            } else {
              this.$message({
                message: log.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false;
        }
      });

    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    onGetCode() {
      const {phone} = this.ruleForm
      if (!phone) {
        this.$message({
          message: '请填写正确的手机号',
          type: 'error'
        })
        return false
      }
      const param = {
        Mobile: phone,
        Type: 2
      }




      this.$post('Captcha/SendCode', param, (code, data) => {
        if (data.code === 200) {
          this.codeDisabled = true
          const that = this
          let time = 120
          const TimeId = setInterval(() => {
            that.codeBtnText = `${time}秒后重发`;
            if (time <= 0) {
              clearInterval(TimeId);
              that.codeDisabled = false;
              that.codeBtnText = `发送验证码`;
            }
            time--
          }, 1000)

          this.$message({
            message: '验证码已成功发送',
            type: 'success'
          })
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
      })


    },

    onLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">

.p-reset-password {
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  // 原始标签样式 start
  figure {
    margin: 0;
    padding: 0;
  }

  // 原始标签样式 end

  // 公用 start
  .p-reset-password-center {
    width: 1280px;
    box-sizing: border-box;
    padding: 0 80px;
    margin: 0 auto;
  }

  // 公用 end

  // 头部
  .p-reset-password-header {
    border-bottom: 1px solid #d4d4d4;
    .p-reset-password-header-box {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      width: 1000px;
      margin: 0 auto;
      align-items: flex-end;
    }

    .p-reset-password-header_left {
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
    }
    .p-reset-password-header_line {
      height: 58px;
      display: inline-block;
      margin: 0 20px;
      width: 1px;
      background-color: #ccc;
    }
    .p-reset-password-header_left_text {
      display: inline-block;
      font-weight: 400;
      font-size: 18px;
      color: #666;
      vertical-align: bottom;
    }


    .p-reset-password-header-text {
      color: #666;

      a {
        color: #666;
        font-size: 12px;

      }

      .p-login-header-text_span {
        display: inline-block;
        margin: 0 5px;

      }
    }
    .p-reset-password-header_logo {
      img {
        width: 220px;
        height: auto;
      }
    }
  }

  // 头部 end

  // 中心
  .p-reset-password-box {
    width: 100%;
    min-height: 400px;
    flex: 1;
    display: flex;
    justify-content: center;
    //align-items: center;
    //align-content: center;

    .p-reset-password-box_content {
      width: 350px;
      margin-top: 30px;


      // 内容样式
      .header {
        font-size: 28px;
        color: #666;
        letter-spacing: 0;
        margin-bottom: 10px;
        text-align: left;
      }

      // 改变组件样式
      .el-form-item {
        margin-bottom: 5px;
      }

      .el-form-item__error {
        top: 10px;
        transform: translate(355px, 0);
      }

      .el-input__inner {
        //height: 30px;
      }
    }



    .login-box-right {
      width: 380px;
      border-radius: 2px 2px 0 0;
      background: #fff
    }
  }

  // 中心 end

  // 底部
  .p-login-footer {
    height: 176px;
    padding-top: 24px;
    font-size: 12px;

    .p-login-footer_content {
      display: flex;
      justify-content: space-between;
      color: #333333;
    }

    .p-login-footer_text {
      p:last-child {
        margin-top: 20px;
      }
    }

    .p-login-footer_img {
      display: flex;

      figure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          text-align-last: justify;
          display: inline-block;

        }
      }

      figure:first-child {
        margin-right: 5px;
      }

      img {
        width: 80px;
        height: 80px;
      }
    }

    .p-login-footer_info {
      text-align: center;
      padding-bottom: 10px;
    }
  }
  // 底部end
}

</style>
